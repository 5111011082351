<template>
    <div v-if="contact_favoris">
    	<div v-for="favoris in contact_favoris" :key="favoris.contact_id">
            <div class="row align-items-center">
                <div class="col">
                    {{ favoris.contact.contact_firstname }} {{ favoris.contact.contact_lastname }}<br>
                    {{ favoris.contact.contact_poste }}
                </div>
                <div class="col-auto ml-auto">
                    <Phone :contact_id="favoris.contact.contact_id" type="picto"></Phone>
                    
                    <i v-b-tooltip.left :title="favoris.contact.contact_mail" v-if="favoris.contact.contact_mail != ''" class="mx-2">
                        <font-awesome-icon :icon="['fal', 'envelope']"/>
                    </i>
                </div>
            </div>
            <hr>
        </div>
    </div>
</template>


<script type="text/javascript">
	import Vue from 'vue'
	import { EventBus } from 'EventBus';
    import Swiper from "GroomyRoot/components/Swiper";
    import HeaderTab from "@/components/HeaderTab";
    import CustomTable from 'GroomyRoot/components/Table/CustomTable';
    
    import Coordonnees from '@/components/Tiers/Coordonnees';
    import LoadingSpinner from 'GroomyRoot/components/Logos/LoadingSpinner_35';
    import Phone from '@/components/Tiers/Phone';

    import Navigation from "@/mixins/Navigation.js";
    import Tiers from "@/mixins/Tiers.js"

	export default {
		name: "contactFavoris",
		mixins: [Navigation, Tiers],
		data () {
			return {
                contact_favoris: null
			}
		},
		props: {
		    tiers_id: { type: Number, default: 0}
		},

		mounted() {
            this.init_component()
		},

		methods: {
            async init_component() {
                // CHECK ICI
                // est valide et tout et tout
                if(this.tiers_id != 0){
					this.contact_favoris = await this.loadContactFavoris(this.tiers_id)
                }
            },
		},
		components: {
            Swiper,
            HeaderTab,
            Coordonnees,
            LoadingSpinner,
            Phone,
            CustomTable
		}
	}
</script>
